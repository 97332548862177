import Page from "components/pages/Page";
import Panel from "components/panels/Panel";
import useTranslation from 'language/useTranslation';


export const FinanceInstitutionPage = () => {

  const { t } = useTranslation();


  return (
    <Page>
        <Panel  title={t('finance_institutions.label')} 
                subTitle={t('finance_institutions.description')} 
                image="/images/assets/finance_institutions.png">
        </Panel>
    </Page>
  );
}
